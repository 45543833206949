<template>
  <div class="container mt-5 mb-md-4 py-5">
    <breadcrumb class="mb-3 mb-md-4 pt-md-3" :crumbs="crumbs"></breadcrumb>

    <!-- Page content -->
    <div class="row justify-content-center pb-sm-2 pt-3">
      <div class="col-lg-6">
        <h1 class="h2 text-center pb-4 mb-3">Additional security required</h1>

        <div class="bg-light rounded-3 py-4 px-md-4 text-left">
          <label class="form-label" for="otp">Two factor authentication code <span class="text-danger">*</span></label>

          <input type="text" id="otp"
                 @keyup.enter="verify"
                 placeholder="Please enter the code from your authenticator app"
                 class="form-control" v-model="otp">

          <div class="bottom-toolbar">
            <div class="left">
              <a href="#" @click.prevent="logout">Logout</a>
            </div>
            <div class="right">
              <a :class="{'opacity-50': isSaving}"
                 @click.prevent="verify"
                 class="btn btn-primary btn-lg rounded-pill ms-sm-auto mt-3">
                <spinner v-if="isSaving" class="spinner-border-sm me-2"></spinner>
                <i v-else class="fi-lock fs-sm me-2"></i>
                Verify OTP</a>
            </div>
          </div>


        </div>
      </div>
    </div>
    <!-- / Page content -->
  </div>
</template>

<script>
import Spinner from "../components/Spinner";
import twoFactorAuthentication from "../api/two-factor-authentication";
import Forms from "../mixins/Forms";
import {mapActions} from 'vuex';
import Breadcrumb from "../components/Breadcrumb";

import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "2FA",
  mixins: [Forms],
  components: {Spinner, Breadcrumb},
  data() {
    return {
      isSaving: false,
      otp: '',
      serverErrors: [],
      crumbs: [
        {
          href: '#',
          label: 'Login'
        },
        {
          href: '#',
          label: '2FA',
          active: true
        }
      ],
    }
  },
  methods: {
    ...mapActions('missionControl', {
      launch: 'launch'
    }),

    ...mapActions('auth', {
      setOtpVerified: 'setOtpVerified',
      logout: 'logout'
    }),

    verify() {
      let vm = this;
      vm.isSaving = true;
      twoFactorAuthentication.verifyOtp(this.otp).then(r => {
        vm.isSaving = false;
        if (Boolean(r.data.verified)) {
          let r = '/select-user-role?to=' + this.$route.query.to ? this.$route.query.to : '/';
          vm.setOtpVerified();
          vm.$router.push(r);
          // vm.launch();
        } else {
          vm.otp = '';
          toast.error('Invalid OTP. Please try again.');
        }
      }).catch(e => {
        vm.isSaving = false;
        vm.setAndNotifyErrors(e, 'Error verifying OTP');
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-toolbar {
  display: flex;
  align-items: flex-end;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}

.two-factor-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .two-factor-inner {
    text-align: center;

    .input-wrapper {
      max-width: 400px;
      margin: 0 auto;
      margin-top: 30px;
      //display: flex;

      a {
        margin-left: 10px;
      }
    }
  }
}
</style>